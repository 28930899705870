/* .portfolio {
  width: 100%;
} */

.background {
  background: #2c3e50;
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: -1;
}

.rocket {
  transition: transform 20ms;
}

.rocket__trail {
  animation: rocket-trail 1000ms infinite;
}

.rocket__trail-container {
  transition: all 300ms;
  opacity: 0.3;
  transform: scale(1);
}

@keyframes rocket-trail {
  0%   {transform: scale(1);}
  50%   {transform: scale(1)translate(0px, 5px);}
}

.header {
  color: white;
  position: relative;
  width: 100%;
}

.header__content {
  position: fixed;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  text-align: center;
}

.footer {
  color: white;
  position: relative;
  width: 100%;
}

.footer__content {
  position: fixed;
  left: 50%;
  width: 100%;
  text-align: center;
}

.footer__heading {
  font-size: 32px;
}

.footer__email {
  font-size: 18px;
  color: white;
}

.projects {
  margin: 150px 0;
}

.project {
  display: flex;
  flex-direction: row;
  color: white;
  width: 100%;
  align-items: center;
}

.project__col {
  width: 50%;
  padding: 25px 100px;
  text-align: center;
}


.project__title {
  font-size: 28px;
  margin-bottom: 40px;
}

.project__description {
  margin-bottom: 40px;
  font-size: 16px;
}

.project__url {
  color: white;
}

.project__screenshot {
  border-radius: 8px;
  box-shadow: 0px 0px 2 px white;
  width: 100%;
  max-width: 800px;
}

.project__github {
  color: white;
  font-size: 18px;
  align-items: center;
  display: flex;
  justify-content: center;
}

.project__github--icon {
  color: white;
  font-size: 28px;
  margin-right: 8px;
}

.project:not(:last-child) {
  margin: 0 0 300px 0;
}

.footer__social {
  margin: 40px 5px;
  font-size: 42px;
  color: white;
}

@media screen and (max-width: 1000px) {
  .project {
    flex-direction: column;
  }

  .project__col {
    width: 80%;
    padding: 25px 50px 25px 10px;
    text-align: center;
  }
}